import { useCallback, useMemo } from 'react';
import {
    formChangedByKey,
    formInvalidIdsByKey,
    formWrapperRecoil,
    getFormByKey,
    IForm,
    isPopulatedSelector,
    TFormValue,
} from '../state/formWrapper';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { IEntityField } from '../../../../../interfaces/item/IEntityField';
import _ from 'lodash';

export const useFormWrapper = (formKey: string) => {
    const setFormStateParent = useSetRecoilState(formWrapperRecoil);

    const isPopulated = useRecoilValue(isPopulatedSelector);
    const setIsPopulated = useSetRecoilState(isPopulatedSelector);

    const [formState, setFormState] = useRecoilState(getFormByKey(formKey));

    const [changeState, setChangeState] = useRecoilState(formChangedByKey(formKey));

    const [invalidState, setInvalidState] = useRecoilState(formInvalidIdsByKey(formKey));

    const setValidIdStateToForm = useCallback(
        (id: string, isValid: boolean) => {
            setInvalidState((currVal) => {
                const newState = { ...currVal };
                if (!newState[formKey]) {
                    newState[formKey] = [];
                }
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                if (isValid) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                    newState[formKey] = newState[formKey].filter((i) => i !== id);
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                    newState[formKey].push(id);
                }
                return newState;
            });
        },
        [formKey, setInvalidState]
    );

    const isValid = useMemo((): boolean => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
        return invalidState[formKey] && invalidState?.[formKey].length > 0;
    }, [invalidState, formKey]);

    /**
     * @description This variable is used to determine if the form has changes
     * @returns boolean
     * @default false
     */
    const hasChanges = useMemo(() => {
        return changeState !== null;
    }, [changeState]);

    /**
     * @description This function is used to reset the changes of the form
     * @returns void
     * @default false
     */
    const resetChanges = useCallback(() => {
        setChangeState(null);
    }, [setChangeState]);

    /**
     * @description This function is used to populate the form state with the data from the backend
     * @param data The data to populate the form with
     * @returns void
     */
    const populateForm = useCallback(
        (data: IForm) => {
            setFormStateParent((currVal) => {
                const newState = { ...currVal, forms: { ...currVal.forms } };
                newState.forms[formKey] = data;
                return newState;
            });

            setIsPopulated(true);
        },
        [formKey, setFormStateParent, setIsPopulated]
    );

    /**
     * @description This function is used to get the value of a form field
     * @param key The key of the form field
     * @deprecated use setValue2 instead
     * @returns The value of the form field
     */
    const getValue = useCallback(
        (key: string): TFormValue | null => {
            if (!formState) return null;

            // Test if the key is a string with a . (dot) RegExp like "config.label"
            const isPath = new RegExp(/[a-zA-Z0-9]+\.[a-zA-Z0-9]+/).test(key);

            if (isPath) {
                // If the key is a path
                const path = key.split('.')[0] as string;
                const subKey = key.split('.')[1] as string;

                if (path === 'fields') {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (formState[path] && formState[path].length > 0) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        const entityField = (formState[path] as IEntityField[]).find(
                            (field: IEntityField) => field.name === subKey
                        ) as IEntityField;

                        return entityField?.value ?? '';
                    }
                } else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (formState[path] && formState[path][subKey]) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        return (formState[path] as TFormValue)[subKey] as TFormValue;
                    }
                }
            } else {
                // If the key is not a path, it's a plain key
                return formState[key] as TFormValue;
            }

            return null; // return null if nothing found
        },
        [formState]
    );

    // dot-prop variant
    const setValue2 = useCallback(
        (key: string, value: TFormValue) => {
            setFormState((currVal) => {
                let newState: IForm | null = _.cloneDeep(currVal) as unknown as IForm | null;

                newState = _.set(newState as never, key, value);

                // newState = setProperty(newState as never, key, value);
                return newState;
            });
            setChangeState(Date.now());
        },
        [setChangeState, setFormState]
    );

    // dot-pro getValue2
    const getValue2 = useCallback(
        (key: string): TFormValue | null => {
            if (!formState) return null;

            return _.get(formState, key) as TFormValue;
        },
        [formState]
    );

    /**
     * @description This function is used to set the value of a form field
     * @param key The key of the form field
     * @param value The value of the form field
     * @deprecated use setValue2 instead
     * @returns void
     */
    const setValue = useCallback(
        (key: string, value: TFormValue) => {
            // Test is key is a string with a . (dot) regExp like "config.label" or "config.required"
            const isPath = new RegExp(/[a-zA-Z0-9]+\.[a-zA-Z0-9]+/).test(key);

            if (isPath) {
                // Write into a possible path like "config.label" (only depth 1)
                setFormState((currVal) => {
                    const newState = { ...currVal };

                    // Get the parts of the path
                    const path = key.split('.')[0] as string;
                    const subKey = key.split('.')[1] as string;

                    // TODO Hier sehe ich noch einen Fehler in der Logik
                    // Das problem ist das hier eine Dimension zu viel ist wenn man fields direkt speichern wird.

                    if (path === 'fields') {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        if (newState[path] && newState[path].length > 0) {
                            let found = false;
                            newState[path] = (newState[path] as IEntityField[]).map(
                                (field: IEntityField) => {
                                    if (field.name === subKey) {
                                        found = true;
                                        return { name: field.name, value: value };
                                    }
                                    return field;
                                }
                            );

                            if (!found) {
                                (newState[path] as IEntityField[]).push({
                                    name: subKey,
                                    value: value as string,
                                } as never);
                            }
                        } else {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            newState[path] = [{ name: subKey, value }];
                        }

                        return newState;
                    } else {
                        // Define the path if it does not exist
                        if (!newState[path]) {
                            newState[path] = {};
                        }

                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        newState[path] = { ...newState[path], [subKey]: value };
                        return newState;
                    }
                });
            } else {
                // Plain key
                setFormState((currVal) => {
                    const newState = { ...currVal };
                    newState[key] = value;
                    return newState;
                });
            }

            // Update the change state to trigger the formChangedByKey selector
            setChangeState(Date.now());
        },
        [setChangeState, setFormState]
    );

    return {
        populateForm,
        formState,
        setFormState,
        getValue,
        getValue2,
        setValue,
        setValue2,
        hasChanges,
        resetChanges,
        isPopulated,
        setValidIdStateToForm,
        isValid,
        changeState,
    };
};
