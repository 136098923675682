import React, { useMemo } from 'react';
import { ChevronDownIcon, ChevronUpIcon, TrashIcon } from '@heroicons/react/16/solid';
import { IconButton } from '../button/IconButton';
import Link from 'next/link';

export interface IAdminMultiSelectItem {
    id: string;
    value: string;
    label: string;
    subLine: string;
    sortable?: boolean;
    hideDeleteButton?: boolean;
    hideHref?: boolean;
    deleteFunction?: (string) => void;
    selectFunction?: (string) => void;
    sortUpAction?: (id: string) => void;
    sortDownAction?: (id: string) => void;
    testId?: string;
    href?: string;
    isFirst?: boolean;
    isLast?: boolean;
    prefix?: JSX.Element;
}

export const AdminMultiSelectItem: React.FC<IAdminMultiSelectItem> = (props) => {
    const {
        id,
        label,
        subLine,
        hideDeleteButton,
        sortable,
        deleteFunction,
        selectFunction,
        sortDownAction,
        sortUpAction,
        isFirst,
        isLast,
        testId,
        href,
        hideHref,
        prefix,
    } = props;

    const borderStyles = useMemo((): string => {
        return 'border-b border-neutral-100';
    }, []);

    return (
        <div
            className={`relative my-auto flex h-max w-full flex-row items-start gap-x-2 px-3 py-2 ${borderStyles}`}
        >
            {/* Content */}
            <div
                data-test-id={testId}
                className={`relative flex w-full flex-col ${
                    selectFunction ? 'hover:cursor-pointer' : ''
                }`}
                onClick={selectFunction ? () => selectFunction(id) : () => {}}
            >
                <div className="pb-1 text-base text-primary">
                    {href && !hideHref && (
                        <Link
                            href={href}
                            target={`item-${label}`}
                            data-test-id={`${testId ?? ''}-link`}
                            className="underline"
                        >
                            {prefix && <span>{prefix}</span>}
                            <span>{label}</span>
                        </Link>
                    )}
                    <div className="relative flex flex-row items-center gap-1">
                        {prefix && <span>{prefix}</span>}
                        {(!href || hideHref) && <span>{label}</span>}
                    </div>
                </div>
                <div className="text-xs text-text-light">{subLine}</div>
            </div>

            {/* X-Button */}
            <div className="relative flex flex-row gap-1">
                {sortable && (
                    <div className="relative flex flex-row gap-1">
                        {!isFirst && (
                            <IconButton
                                testId={`${testId ?? ''}-remove`}
                                onClick={() => {
                                    if (sortUpAction) {
                                        sortUpAction(id);
                                    }
                                }}
                            >
                                <ChevronUpIcon className="h-5 text-text-primary" />
                            </IconButton>
                        )}
                        {isLast && <div className="w-9"></div>}
                        {!isLast && (
                            <IconButton
                                testId={`${testId ?? ''}-remove`}
                                onClick={() => {
                                    if (sortDownAction) {
                                        sortDownAction(id);
                                    }
                                }}
                            >
                                <ChevronDownIcon className="h-5 text-text-primary" />
                            </IconButton>
                        )}
                    </div>
                )}

                {!hideDeleteButton && (
                    <IconButton
                        testId={`${testId ?? ''}-remove`}
                        onClick={deleteFunction ? () => deleteFunction(id) : () => {}}
                    >
                        <TrashIcon className="h-5 text-text-primary" />
                    </IconButton>
                )}
            </div>
        </div>
    );
};
