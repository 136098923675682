import { atom, selector, selectorFamily } from 'recoil';

export type TFormValue = string | number | boolean | null | string[];

export interface IForm {
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    [key: string]: TFormValue | unknown;
}

export interface IFormWrapper {
    formKey: string;
    isPopulated: boolean;
    // Form data per form key
    forms: {
        [key: string]: IForm;
    };
    // Timestamps per form key
    formChanged: {
        [key: string]: number | null;
    };
    formInvalidIds?: {
        [key: string]: string[];
    };
}

export const formWrapperRecoil = atom<IFormWrapper>({
    key: `form-wrapper-state`,
    default: {
        isPopulated: false,
        formKey: '',
        forms: {},
        formChanged: {},
        formInvalidIds: {},
    },
});

export const getFormByKey = selectorFamily<IForm | null, string>({
    key: 'getFormByKey',
    get:
        (formKey) =>
        ({ get }) => {
            const forms = get(formWrapperRecoil).forms;

            const form = forms[formKey];

            if (!form) {
                return null;
                // throw new Error(`Form with key ${formKey} does not exist`);
            }
            return form;
        },
    set:
        (formKey) =>
        ({ set }, newForm) => {
            set(formWrapperRecoil, (prevState) => {
                // You may need to deep copy the prevState to avoid directly mutating it
                // This is a shallow copy example:
                const newForms = { ...prevState.forms };
                newForms[formKey] = newForm as IForm; // casting because newForm is of type DefaultValue | IForm
                return { ...prevState, forms: newForms };
            });
        },
});

export const formChangedByKey = selectorFamily<number | null, string>({
    key: 'formChangedByKey',
    get:
        (formKey) =>
        ({ get }) => {
            // Retrieve the formChanged timestamps
            const formChanged = get(formWrapperRecoil).formChanged;
            // Return the timestamp for the specific formKey or null if it does not exist
            return formChanged[formKey] || null;
        },
    set:
        (formKey) =>
        ({ set }, newTimestamp) => {
            // Ensure that the newTimestamp is a number, or it's being reset with the DefaultValue
            if (typeof newTimestamp !== 'number' && newTimestamp !== null) {
                throw new Error('newTimestamp must be a number');
            }
            // Update the timestamp for the specific formKey
            set(formWrapperRecoil, (prevState) => {
                // Deep copy prevState to avoid directly mutating it
                const newFormChanged = { ...prevState.formChanged };

                newFormChanged[formKey] = newTimestamp; // casting because newTimestamp can be DefaultValue

                // Return the new state
                return { ...prevState, formChanged: newFormChanged };
            });
        },
});

export const formInvalidIdsByKey = selectorFamily<string[], string>({
    key: 'formInvalidIdsByKey',
    get:
        (formKey) =>
        ({ get }) => {
            const formInvalidIds = get(formWrapperRecoil).formInvalidIds;
            return formInvalidIds?.[formKey] || [];
        },
    set:
        (formKey) =>
        ({ set }, newInvalidIds) => {
            set(formWrapperRecoil, (prevState) => {
                const newFormInvalidIds = { ...prevState.formInvalidIds };
                // We're ensuring that newFormInvalidIds is an object before attempting to assign a property
                if (newFormInvalidIds && newFormInvalidIds[formKey]) {
                    newFormInvalidIds[formKey] = newInvalidIds as string[];
                }
                return { ...prevState, formInvalidIds: newFormInvalidIds };
            });
        },
});

export const formKeySelector = selector<string>({
    key: 'form-key-selector',
    get: ({ get }) => {
        return get(formWrapperRecoil).formKey;
    },
    set: ({ set }, newFormKey) => {
        set(formWrapperRecoil, (prevState) => {
            return { ...prevState, formKey: newFormKey as string };
        });
    },
});

export const isPopulatedSelector = selector<boolean>({
    key: 'isPopulatedSelector',
    get: ({ get }) => {
        return get(formWrapperRecoil).isPopulated;
    },
    set: ({ set }, isPopulated) => {
        set(formWrapperRecoil, (prevState) => {
            return { ...prevState, isPopulated: isPopulated as boolean };
        });
    },
});
