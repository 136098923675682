/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { AdminMultiSelectItem, IAdminMultiSelectItem } from './AdminMultiSelectItem';
import { Transition } from '@headlessui/react';
import Fuse from 'fuse.js';
import { Label } from '../../typo/label/Label';
import { PlusIcon, XMarkIcon } from '@heroicons/react/16/solid';
import { IconButton } from '../button/IconButton';
import { useClickOutsideToClose } from '../../hooks/ClickOutToCloseHandler/useClickOutsideToClose';

export interface IAdminMultiSelect {
    testId?: string;
    label?: string;
    isRequired?: boolean;
    placeholder?: string;
    options?: IAdminMultiSelectItem[];
    selected?: string[] | string;
    action?: (values: string[] | string) => void;
    singleSelect?: boolean;
    sortable?: boolean;
}

export const AdminMultiSelect: React.FC<IAdminMultiSelect> = (props) => {
    const {
        label,
        isRequired,
        placeholder,
        options,
        selected,
        action,
        singleSelect,
        testId,
        sortable,
    } = props;
    const ref = useRef<HTMLInputElement | null>(null);

    const [search, setSearch] = useState('');
    const [showSearchResults, setShowSearchResults] = useState(false);

    const dropdownRef = useRef<HTMLDivElement | null>(null);

    useClickOutsideToClose(
        dropdownRef,
        () => {
            setShowSearchResults(false);
        },
        showSearchResults
    );

    const selectedOption = useMemo((): string[] => {
        if (!selected) return [];

        // Selected option can be string or string[]; make sure it's always an array
        if (typeof selected === 'string') {
            return [selected];
        }
        return selected;
    }, [selected]);

    const removeSelectedItem = (id: string) => {
        const temp = selectedOption?.filter((el) => el !== id);
        if (action) {
            action(temp);
        }
    };

    const clearOrCloseSearch = () => {
        if (search === '') {
            setShowSearchResults(false);
        } else {
            setSearch('');
        }
    };

    const filteredOptions = useMemo(() => {
        if (search && options) {
            const fuse = new Fuse(options, {
                keys: ['id', 'label', 'subLine'],
                shouldSort: true,
                includeScore: true,
                threshold: 0.5,
                distance: 100,
                findAllMatches: false,
                minMatchCharLength: 1,
            });

            const fuzzed = fuse.search(search);

            const filtered: IAdminMultiSelectItem[] = [];

            fuzzed.forEach((item) => filtered.push(item.item));

            // TODO!
            // Filter out if selectedOption.includes(item.id)
            // filtered.filter((item) => !selectedOption.includes(item.id));

            return filtered;
        }
        return options;
    }, [options, search]);

    const selectItem = (id: string) => {
        // check if items is not already added to the selection

        const alreadySelected = selectedOption?.find((el) => el === id);

        if (alreadySelected) {
            setShowSearchResults(false);
            setSearch('');
            return;
        }

        if (singleSelect) {
            if (action) {
                action(id);
            }

            setShowSearchResults(false);
            setSearch('');

            return;
        }

        const temp = [...selectedOption];
        temp.push(id);

        // sort through labels
        temp.sort((a, b) => {
            const optionA = options?.find((el) => el.id === a);
            const optionB = options?.find((el) => el.id === b);

            if (optionA && optionB) {
                if (optionA?.label > optionB?.label) return 1;
                if (optionA?.label < optionB?.label) return -1;
            }
            return 0;
        });

        if (action) {
            action(temp);
        }
        setShowSearchResults(false);
        setSearch('');
    };

    const placeholderText = useMemo((): string => {
        if (!placeholder) return '';

        // TODO: Check if there are no more options
        if (filteredOptions && filteredOptions.length === 0) {
            return 'Keine weiteren Optionen';
        }

        return placeholder;
    }, [filteredOptions, placeholder]);

    const reOrderItemInDataUp = useCallback(
        (id: string) => {
            // create a copy of the dataTest state
            const newData = [...selectedOption];

            // find the index of the item
            const index = newData.findIndex((item) => item === id);

            // check if the item is not the first one in the array
            if (index > 0 && newData && newData[index]) {
                // switch items
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                [newData[index], newData[index - 1]] = [newData[index - 1], newData[index]];

                // update dataTest state
                // setselectedOption(newData);
                if (action) {
                    void action(newData);
                }
            }
        },
        [action, selectedOption]
    );

    const reOrderItemInDataDown = useCallback(
        (id: string) => {
            // create a copy of the dataTest state
            const newData = [...selectedOption];

            // find the index of the item
            const index = newData.findIndex((item) => item === id);

            // check if the item is not the last one in the array
            if (index >= 0 && index < newData.length - 1) {
                // switch items
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                [newData[index], newData[index + 1]] = [newData[index + 1], newData[index]];

                // update dataTest state
                if (action) {
                    void action(newData);
                }
            }
        },
        [action, selectedOption]
    );

    return (
        <div className="relative mt-4 flex h-max w-full flex-col">
            {/*label*/}
            {label && (
                <div className={'relative flex h-6 flex-row items-center font-light'}>
                    <Label>
                        {label}
                        {isRequired && (
                            <span className="relative inline -top-0.5 text-danger">*</span>
                        )}
                    </Label>
                </div>
            )}

            {/* input */}
            <div
                className={
                    'relative flex h-[40px] w-full flex-row items-center gap-2 rounded-[3px] border border-border pl-[13px] text-sm'
                }
            >
                <input
                    ref={ref}
                    value={search}
                    type="text"
                    placeholder={placeholderText}
                    data-test-id={`${testId ?? ''}-input`}
                    className="z-20 w-full border-0 bg-transparent pr-12 text-base font-normal text-text-default placeholder:text-sm placeholder:text-text-light"
                    onChange={(e) => {
                        setSearch(e.target.value ?? '');
                    }}
                />
                {!showSearchResults && !search && (
                    <IconButton
                        testId={`${testId ?? ''}-button-open`}
                        wrapperCss="relative right-1 w-[36px] h-[36px] z-10"
                        onClick={() => setShowSearchResults(true)}
                    >
                        <PlusIcon data-test-id={`${testId ?? ''}-open`} />
                    </IconButton>
                )}
                {(showSearchResults || search) && (
                    <IconButton
                        testId={`${testId ?? ''}-button-close`}
                        wrapperCss="relative right-1 w-[36px] h-[36px] z-10"
                        onClick={() => clearOrCloseSearch()}
                    >
                        <XMarkIcon data-test-id={`${testId ?? ''}-clear-search`} />
                    </IconButton>
                )}

                <div className="absolute left-0 z-30 w-full overflow-y-auto overflow-x-hidden pb-1 top-[38px] max-h-[330px] px-[3px]">
                    <Transition
                        as={'div'}
                        className={`relative w-full`}
                        show={showSearchResults || !!search}
                        appear={showSearchResults || !!search}
                        enter="transition ease-in-out duration-150 transform"
                        enterFrom="-translate-y-full"
                        enterTo="translate-y-0"
                        leave="transition ease-in-out duration-100 transform"
                        leaveFrom="translate-y-0"
                        leaveTo="-translate-y-full"
                    >
                        <div
                            className="relative flex h-max w-full flex-col rounded border border-neutral-200 bg-neutral-50 shadow gap-[1px]"
                            data-test-id={`${testId ?? ''}-search-results`}
                            ref={dropdownRef}
                        >
                            {filteredOptions &&
                                filteredOptions.map((item) => {
                                    if (selectedOption.includes(item.id)) return null;

                                    return (
                                        <AdminMultiSelectItem
                                            testId={`${testId ?? ''}-available-item-${item.id}`}
                                            key={item.id}
                                            {...item}
                                            selectFunction={selectItem}
                                            hideDeleteButton={true}
                                            hideHref={true}
                                        />
                                    );
                                })}
                        </div>
                    </Transition>
                </div>
            </div>

            {/* selected choices container */}
            <div className="relative flex h-max w-full flex-col gap-2 px-[3px]">
                {selectedOption &&
                    selectedOption.map((id, index) => {
                        const item = options?.find((el) => el.id === id);

                        if (item) {
                            return (
                                <AdminMultiSelectItem
                                    sortable={sortable}
                                    key={item.id}
                                    {...item}
                                    deleteFunction={removeSelectedItem}
                                    testId={`${testId ?? ''}-selected-item-${item.id}`}
                                    hideHref={false}
                                    sortUpAction={reOrderItemInDataUp}
                                    sortDownAction={reOrderItemInDataDown}
                                    isFirst={index === 0}
                                    isLast={index === selectedOption.length - 1}
                                />
                            );
                        }
                    })}
            </div>
        </div>
    );
};
